<template>
  <v-card :loading="loading">
    <v-card-title class="align-start">
      <span class="font-weight-semibold text-base"
        >Estatísticas de avaliações</span
      >

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="loading"
            @click="fetchDatasTotalizers()"
            icon
            small
            class="me-n3 mt-n2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="actions">mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Atualizar</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6" md="3">
          <div class="d-flex">
            <v-avatar size="44" color="info" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                mdi-clipboard-clock
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Abertas</p>
              <span class="text--primary text-xl font-weight-bold">{{
                dataTotalizers.abertas ? dataTotalizers.abertas : 0
              }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="6" md="3">
          <div class="d-flex">
            <v-avatar size="44" color="warning" rounded class="elevation-1">
              <v-icon dark color="white" size="30"> mdi-clipboard-edit </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Pendentes</p>
              <span class="text--primary text-xl font-weight-bold">{{
                dataTotalizers.pendentes ? dataTotalizers.pendentes : 0
              }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="6" md="3">
          <div class="d-flex">
            <v-avatar size="44" color="success" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                mdi-clipboard-check
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Finalizadas</p>
              <span class="text--primary text-xl font-weight-bold">{{
                dataTotalizers.finalizadas ? dataTotalizers.finalizadas : 0
              }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="6" md="3">
          <div class="d-flex">
            <v-avatar size="44" color="error" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                mdi-clipboard-remove
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Inválidas</p>
              <span class="text--primary text-xl font-weight-bold">{{
                dataTotalizers.invalidas ? dataTotalizers.invalidas : 0
              }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CardStatisticsCard",
  data() {
    return {
      dataTotalizers: {},
      loading: false,
    };
  },
  created() {
    this.fetchDatasTotalizers();
  },
  methods: {
    async fetchDatasTotalizers() {
      this.loading = true;

      const bodyDataTotalizers = {
        uuid: this.$route.params.uuid,
      };

      this.dataTotalizers = await this.$store.dispatch(
        "pesquisas/fetchDatasTotalizers",
        bodyDataTotalizers
      );

      this.loading = false;
    },
  },
};
</script>
