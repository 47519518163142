<template>
  <section>
    <v-row>
      <v-col class="d-flex pb-5" cols="12">
        <v-btn
          color="primary"
          outlined
          class="mr-auto"
          :to="{ name: 'pco-pesquisas' }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            mdi-arrow-left-thin
          </v-icon>
          <span>voltar</span>
        </v-btn>
        <v-btn
          color="primary"
          class=""
          @click="exportarPesquisa()"
          :disabled="loadingExportacao"
          :loading="loadingExportacao"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            mdi-file-download-outline
          </v-icon>
          <span>exportar</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <!-- Estatísticas de avaliações -->
      <v-col cols="12" md="12">
        <CardStatisticsCard></CardStatisticsCard>
      </v-col>
      <!-- Filtros 
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title class="align-start">
            <span class="font-weight-semibold text-base"> Filtros </span>
            <v-spacer></v-spacer>
            <v-btn icon small class="me-n3 mt-n2">
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row> </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      -->
    </v-row>
    <v-row>
      <v-col cols="12" v-if="dadosPainel.length == 0">
        <v-sheet :color="`grey 'darken-2'`" class="pa-3">
          <v-skeleton-loader
            class="mx-auto"
            type="article, actions"
          ></v-skeleton-loader>
        </v-sheet>
      </v-col>

      <v-col cols="12" md="12" v-else>
        <v-tabs show-arrows center-active v-model="tabSelected[0]">
          <v-tab
            v-for="(tab, index) in tabsOptions"
            :disabled="tab.disabled"
            :key="index"
            :href="`#tab-${index}`"
            style="color: #5c6da7"
          >
            {{ tab.name }}
          </v-tab>
          <v-tab-item
            v-for="(tab, index) in tabsOptions"
            :key="index"
            :value="'tab-' + index"
          >
            <component :ref="tab.ref" :is="tab.component"></component>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </section>
</template>

<script>

//demos
import CardStatisticsCard from "../components/CardStatisticsCard.vue";

export default {
  name: "PcoDashboardView",
  components: {
    CardStatisticsCard,
  },
  created() {
    this.$store.dispatch(
      "pesquisas/fetchStatusPesquisa",
      this.$route.params.uuid
    );

    this.$store.dispatch("pesquisas/buscarDadosPainel", {
      uuid: this.$route.params.uuid,
    });
  },
  methods: {
    exportarPesquisa() {
      this.$store.dispatch("pesquisas/exportarPesquisa", {
        uuid: this.$route.params.uuid,
      });
    }
  },
  computed: {
    tabsOptions: {
      get() {
        return this.$store.getters["pesquisas/getTabsPainel"];
      },
    },
    tabSelected: {
      get() {
        return this.$store.getters["pesquisas/getTabPainel"];
      },
    },
    dadosPainel: {
      get() {
        return this.$store.getters["pesquisas/getDadosPainel"];
      },
    },
    pesquisa: {
      get() {
        return this.$store.getters["pesquisas/getPesquisa"];
      },
    },
    loadingExportacao: {
      get() {
        return this.$store.getters["pesquisas/getLoadingExportacao"];
      },
    },
  },
};
</script>
