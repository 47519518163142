import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c(VRow,[_c(VCol,{staticClass:"d-flex pb-5",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"mr-auto",attrs:{"color":"primary","outlined":"","to":{ name: 'pco-pesquisas' }}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" mdi-arrow-left-thin ")]),_c('span',[_vm._v("voltar")])],1),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.loadingExportacao,"loading":_vm.loadingExportacao},on:{"click":function($event){return _vm.exportarPesquisa()}}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" mdi-file-download-outline ")]),_c('span',[_vm._v("exportar")])],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('CardStatisticsCard')],1)],1),_c(VRow,[(_vm.dadosPainel.length == 0)?_c(VCol,{attrs:{"cols":"12"}},[_c(VSheet,{staticClass:"pa-3",attrs:{"color":"grey 'darken-2'"}},[_c(VSkeletonLoader,{staticClass:"mx-auto",attrs:{"type":"article, actions"}})],1)],1):_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VTabs,{attrs:{"show-arrows":"","center-active":""},model:{value:(_vm.tabSelected[0]),callback:function ($$v) {_vm.$set(_vm.tabSelected, 0, $$v)},expression:"tabSelected[0]"}},[_vm._l((_vm.tabsOptions),function(tab,index){return _c(VTab,{key:index,staticStyle:{"color":"#5c6da7"},attrs:{"disabled":tab.disabled,"href":("#tab-" + index)}},[_vm._v(" "+_vm._s(tab.name)+" ")])}),_vm._l((_vm.tabsOptions),function(tab,index){return _c(VTabItem,{key:index,attrs:{"value":'tab-' + index}},[_c(tab.component,{ref:tab.ref,refInFor:true,tag:"component"})],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }